// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function() {
  if(document.location.pathname.match(/^\/event_logs/)){
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });
  }
});
