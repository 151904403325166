// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/delivery_destinations$/)){
    let $table = $('#delivery_destination-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }
    set_icheck();
    
    $('#delivery_destination-pager').on('ajax:success', 'li a', function(event){
      $('#delivery_destination-pager').html(event.detail[0]['paginator']);
      $('#delivery_destination-list > tbody').html(event.detail[0]['delivery_destinations']);
      set_icheck();
    });

    $('#new-delivery_destination').on('click', function(){
      $.ajax({
        url: '/delivery_destinations/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#delivery_destination-attr-form').replaceWith(json.html);
            changeIsCartageIncluded();
            replaceCharacter();
            setJpostal();
            set_icheck();
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });

      return false;
    });

    $('#change-delivery_destination').on('click', function(){
      let id = get_delivery_destination_id();
      if (id == null) {
        alert('納入先を選択してください');
        return false;
      }
      $.ajax({
        url: '/delivery_destinations/form/' + id + '.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#delivery_destination-attr-form').replaceWith(json.html);
            changeIsCartageIncluded();
            replaceCharacter();
            setJpostal();
            set_icheck();
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });

      return false;
    });

    function get_delivery_destination_id(){
      let $form = $('#delivery_destination-list-form');
      let list_param = $form.serializeArray();
      let delivery_destination_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          delivery_destination_id = list_param[i]['value'];
        };
      };
      return delivery_destination_id;
    }

    // 部所が持つ「納入先：運送料有フラグの状態」によりフラグ変更
    function changeIsCartageIncluded() {
      $('.busyo_select').change(function(){
        var freight_charge_flg = $('.busyo_select option:selected').data('freight-charge');

        if (freight_charge_flg == 1) {
          $('#delivery_destination_is_cartage_included').iCheck('check')
        } else {
          $('#delivery_destination_is_cartage_included').iCheck('uncheck')
        }
      })
    }
    changeIsCartageIncluded();

    // 半角文字を全角文字に変換
    function replaceCharacter() {
      $('.only-full-width').on('keyup change', function(e){
        target = $(e.target);
        value = target.val();
        if (typeof value !== 'string') { return }
  
        replace_value = String(value).replace(/[!-~]/g, function(all) {
          return String.fromCharCode(all.charCodeAt(0) + 0xFEE0);
        });
  
        target.val(replace_value)
      })
    };
    replaceCharacter();


    function setJpostal(){
      $('#delivery_destination_zip1').jpostal({
        postcode : [
          '#delivery_destination_zip1',
          '#delivery_destination_zip2'
        ],
        address : {
          "#delivery_destination_address1" : "%3%4%5"
        }
      })
    }
    setJpostal();
  }
});
