$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/quotation_order_histories$/)){
    restoreBeforeHtml()
    clickLinkButton()

    // カレンダー
    $('.calender').daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY/MM/DD'));
      setValue($(this))
      requestSearch()
    });

    function setValue(target){
      $(target).attr('value', target.val())
    }

    $('.quotation_order_status_button input').on('change', function(e){
      $(this).val($(this).prop('checked'))
      requestSearch()
    })

    $('.input-calender').on('keyup change', function(){
      requestSearch()
    })

    var timer = null;
    $('.input-keyword, .input-text').on('input change', function() {
      clearTimeout(timer);

      timer = setTimeout(function() {
        requestSearch()
        setValue($(this))
      }, 500)
    })

    $('.size-full-code-select').on('change', function(){
      requestSearch()
      setValue($(this))
      $('#size_full_code_val').val($(this).val())
    })

  }

  if(document.location.pathname.match(/^\/quotation_order_histories\/\d+/)){
    $('#order_history_back_button').on('click', function(e){
      e.preventDefault()

      var currentUrl = window.location.href;

      var newUrl = currentUrl.replace(/histories\/\d+/g, 'histories');
      window.location.href = newUrl;
    })
  }

  if(document.location.pathname.match(/^\/quotation_order_histories\/\d+\/confirm/)){
    $('#order_history_back_button').on('click', function(e){
      e.preventDefault()

      history.back();
    })
  }

  if(document.location.pathname.match(/^\/quotation_order_histories\/\d+\/complete/)){
    var beforeHtml = localStorage.getItem('beforeHistoryHtml')
    if (beforeHtml !== null){
      localStorage.removeItem('beforeHistoryHtml')
    }
  }

  function requestSearch(){
    $.ajax({
      url: '/quotation_order_histories/search',
      type: 'post',
      data: {
        quotation_order_history_query:{
          status_requesting: $('#quotation_order_history_query_status_requesting:checked').val(),
          status_ordered: $('#quotation_order_history_query_status_ordered:checked').val(),
          status_order_completed: $('#quotation_order_history_query_status_order_completed:checked').val(),
          order_date_from: $('#quotation_order_history_query_order_date_from').val(),
          order_date_to: $('#quotation_order_history_query_order_date_to').val(),
          preferred_delivery_date_from: $('#quotation_order_history_query_preferred_delivery_date_from').val(),
          preferred_delivery_date_to: $('#quotation_order_history_query_preferred_delivery_date_to').val(),
          quote_expiration_date_from: $('#quotation_order_history_query_quote_expiration_date_from').val(),
          quote_expiration_date_to: $('#quotation_order_history_query_quote_expiration_date_to').val(),
          keyword: $('#quotation_order_history_query_keyword').val(),
          size_full_code: $('.size-full-code-select').val(),
          outer_diameter_min: $('#quotation_order_history_query_outer_diameter_min').val(),
          outer_diameter_max: $('#quotation_order_history_query_outer_diameter_max').val(),
          wall_thickness_min: $('#quotation_order_history_query_wall_thickness_min').val(),
          wall_thickness_max: $('#quotation_order_history_query_wall_thickness_max').val(),
          length_min: $('#quotation_order_history_query_length_min').val(),
          length_max: $('#quotation_order_history_query_length_max').val()
        }
      },
      success: function(json, status, xhr) {
        const contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            if (json.error) {
              alert(json.error);
              return false;
            }
            $('#search_result').html(json.result);
            clickLinkButton();
        }
      },
      error: function(xhr, status, e) {
        alert(status);
      }
    });
  }

  function clickLinkButton(){
    $('.history_show_button, .orderable_button').on('click', function(e){
      localStorage.setItem('beforeHistoryHtml', $('.content').html())
    })
  }

  function restoreBeforeHtml(){
    var beforeHtml = localStorage.getItem('beforeHistoryHtml')
    if (localStorage.getItem('beforeHistoryHtml') == null) {
      $('.select2').select2({placeholder: '選択してください'});
    }else if (beforeHtml !== null){
      $('.content').html(beforeHtml)
      localStorage.removeItem('beforeHistoryHtml')
      code_val = $('#size_full_code_val').val()
      $('span.select2').remove()
      $('.size-full-code-select').val(code_val).trigger('change.select2')
      $('.size-full-code-select').select2().select2({width: "100%", placeholder: '選択してください'})
      $('.quotation_order_status_button input').each(function(){
        ju = $(this).val() == 'true'
        $(this).prop('checked', ju)
      })
    }
  }
})
