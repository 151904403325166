// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function() {
  if(document.location.pathname.match(/^\/notifies\/(new|\d+)$/)){
    var page = 1;
    var loading = false;
    var last_page_loaded = false;

    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    CKEDITOR.config.contentsCss = [ '/ckeditor.css' ];
    CKEDITOR.config.height = 150;
    CKEDITOR.replace('notify_body');

    let $table = $('#company-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function get_company_codes(){
      let hccv = $('#notify_company_codes').val();
      return hccv != "" ? hccv.split(',') : []; // "".split(',') -> [ "" ] になるので場合分けしている
    }

    // 会社一覧のチェックボックスにイベントを追加する
    function add_event_to_company_check(){
      let company_codes = get_company_codes();
      for(let i = 0; i < company_codes.length; i++){
        $('.company_codes[value=' + company_codes[i] + ']').prop('checked', true);
      }

      $('.company_codes').off('change');
      $('.company_codes').on('change', function(event){
        let company_codes = get_company_codes();
        let index = company_codes.indexOf($(event.target).val());
        if($(event.target).prop('checked') && index == -1) {
          company_codes.push($(event.target).val());
        } else if(!$(event.target).prop('checked') && index >= 0){
          company_codes.splice(index, 1);
        }
        // hidden_fieldにお知らせ対象の会社コードを埋め込む
        $('#notify_company_codes').val(company_codes.sort().join(','));
      });
      $('#check_all_company').trigger('change');

    }

    $('#check_all').on('change', function(event){
      if($(event.target).prop('checked')) {
        $('.company_codes').prop('checked', true);
      } else {
        $('.company_codes').prop('checked', false);
      }
      $('.company_codes').trigger('change');
    });

    $('#check_all_company').on('change', function(event){
      if($(event.target).prop('checked')) {
        $('#check_all').prop('disabled', true);
        $('.company_codes').prop('disabled', true);
      } else {
        $('#check_all').prop('disabled', false);
        $('.company_codes').prop('disabled', false);
      }
    });

    // (会社一覧スクロール時に)追加の会社を読み込む
    function load_companies() {
      if (loading || last_page_loaded) {
        return false;
      }
      if ($('#company-list-div').get(0).scrollHeight - $('#company-list-div').height() - 1200 <= $('#company-list-div').scrollTop()) {
        var form = $('#company-form');
        loading = true;
        $.ajax({
          url: form.attr('action'),
          type: form.attr('method'),
          dataType: 'json',
          data: form.serialize() + '&company_query%5Bpage%5D=' + page
        }).done(function(result) {
          last_page_loaded = result.last_page_loaded;
          page++;
          $('#companies').append(result.html);
          add_event_to_company_check();
        }).fail(function(result) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }).always(function(result) {
          loading = false;
        });
      }
    }

    // お知らせ登録・更新時に会社検索のクエリを渡す
    function create_element_of_company_search() {
      var company_form = document.getElementById("company-form");
      var form_data = new FormData(company_form);

      $('#company-query').html('');
      for (let entry of form_data.entries()) {
        if (entry[0].match(/^authenticity_token/)) continue;
        if (entry[0].match(/^_method/)) continue;
        let input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', entry[0]);
        input.setAttribute('value', entry[1]);
        $('#company-query').append(input);
      }
    }

    $('#notify_pdf').on('change', function(){
      let file = this.files[0];
      let mime_types = [ 'application/pdf' ];
      if(mime_types.indexOf(file.type) == -1) {
        alert('アップロードできるファイルはPDFのみです。');
        $('#notify_pdf').val('');
        return;
      }

      if(file.size > 10 * 1024 * 1024) {
        alert('10MB以下のファイルがアップロード可能です。');
        $('#notify_pdf').val('');
        return;
      }

      let canvas = $('#pdf-preview')[0];
      canvas.style.display = 'inline-block';
      $('#pdf-uploaded').hide();

      let pdf_url = URL.createObjectURL(file);
      let loadingTask = pdfjsLib.getDocument(pdf_url);
      loadingTask.promise.then(function(pdf_doc) {
        pdf_doc.getPage(1).then(function(page) {
          let scale = canvas.width / page.getViewport(1).width
          let viewport = page.getViewport({ scale: scale });

          let context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          let renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        });
        URL.revokeObjectURL(pdf_url);
      }).catch(function(error) {
        alert(error.message);
        $('#notify_pdf').val('');
        canvas.style.display = 'none';
      });;
    });

    $('#del-pdf-button').on('click', function(){
      $('#notify_pdf').val('');
      $('#notify_pdf_id').val('');
      $('#pdf-preview').hide();
      $('#pdf-uploaded').hide();
      return false;
    });

    $('#company-form').on('ajax:success', function(event){
      let result = event.detail[0]
      $('#company_query_errors').html("");
      if(result.status){
        $('#companies').html(result.html);
        add_event_to_company_check();
        page = 2;
      } else {
        let error_frame = $('<div class="callout callout-danger" />');
        for(let i = 0; i < result.error_messages.length; i++){
          error_frame.append("<p>" + result.error_messages[i] + "</p>");
        }
        $('#company_query_errors').html(error_frame);
      }
    });

    $('#company-form').on('ajax:error', function(){
      alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
      location.href = '/login';
    });

    $('#reset-company-form-button').on('click', function(){
      $('#company-form .form-control').each(function(index, element){
        $(element).val('');
      });
      return false;
    });

    $('#submit-button').on('click', function(e){
      e.preventDefault();

      let input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'check_all_company');
      input.setAttribute('value', $('#check_all_company').prop('checked'));
      $('#company-query').append(input);

      $('#notify-form').submit();
    });

    $('#company-list-div').scroll(function() {
      load_companies();
    });

    // 会社検索後処理


    $('#company-form').on('ajax:success', function(event){
      create_element_of_company_search();

      var result = event.detail[0];
      last_page_loaded = result.last_page_loaded;
    });

    load_companies();
    create_element_of_company_search();
  }
});
