// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function() {
  if(document.location.pathname.match(/^\/search_invoice$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });
  }

  if(document.location.pathname.match(/^\/search_invoice\/\d+\/preview$/)){
    $('#close_window').on('click', function(){
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });
  }
});