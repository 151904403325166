$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/department_selections/)){
    let $table = $('#department-selection-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }

    $('#department-selection-pager').on('ajax:success', 'li a', function(event){
      $('#department-selection-pager').html(event.detail[0]['paginator']);
      $('#department-selection-list > tbody').html(event.detail[0]['department_selections']);
      set_icheck();
    });

    function get_department_selection_id(){
      let $form = $('#department-selection-list-form');
      let list_param = $form.serializeArray();
      let department_selection_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          department_selection_id = list_param[i]['value'];
        };
      };
      return department_selection_id;
    }

    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }

    $('#change-department-selection').on('click', function(){
      let id = get_department_selection_id();
      if (id == null) {
        alert('部所を選択してください');
        return false;
      }
      $.ajax({
        url: '/department_selections/form/' + id + '.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#department-selection-attr-form').replaceWith(json.html);
          }
          set_icheck();
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });

      return false;
    });


    $('#new-department-selection').on('click', function(){
      $.ajax({
        url: '/department_selections/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#department-selection-attr-form').replaceWith(json.html);
          }
          set_icheck();
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
    
      return false;
    });

    $('#delete-department-selection').on('click', function(){
      let id = get_department_selection_id();
      if (id == null) {
        alert('部所を選択してください');
        return false;
      }
      $('#delete-department-selection-id').val(id);
      $('#delete-modal').modal();
      return false;
    });
  
    defineItemNameCodeSelect();
    set_icheck();
  }
});