$(document).on('turbolinks:load', function(){
    if(document.location.pathname.match(/^\/unit_weights/)){
      let $table = $('#unit-weight-list');
      $table.floatThead({
        scrollContainer: function($table){
          return $table.closest('.table-responsive');
        }
      });

      function defineItemNameCodeSelect() {
        $('.select2').select2({placeholder: '選択してください'});
      }
  
      $('#unit-weight-pager').on('ajax:success', 'li a', function(event){
        $('#unit-weight-pager').html(event.detail[0]['paginator']);
        $('#unit-weight-list > tbody').html(event.detail[0]['unit_weights']);
        set_icheck();
      });
  
      function get_unit_weight_id(){
          let $form = $('#unit-weight-list-form');
          let list_param = $form.serializeArray();
          let unit_weight_id = null;
          for(let i = 0; i < list_param.length; i++) {
            if(list_param[i]['name'] == 'id') {
                unit_weight_id = list_param[i]['value'];
            };
          };
          return unit_weight_id;
        }
  
      function set_icheck(){
        $('.icheck').iCheck({
          checkboxClass: 'icheckbox_square-blue',
          radioClass: 'iradio_square-blue'
        });
      }
      
      $('#new-unit-weight').on('click', function(){
        $.ajax({
          url: '/unit_weights/form/new.json',
          success: function(json, status, xhr) {
            var contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              $('#unit-weight-attr-form').replaceWith(json.html);
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
    
        return false;
      });

      $('#delete-unit-weight').on('click', function(){
          let id = get_unit_weight_id();
          if (id == null) {
            alert('データを選択してください');
            return false;
          }
          $.ajax({
            url: '/unit_weights/' + id + '/steel_stock.json',
            success: function(json, status, xhr) {
              let contentType = xhr.getResponseHeader("content-type") || "";
              if (contentType.indexOf('html') > -1) {
                alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
                location.href = '/login';
              }
              if (contentType.indexOf('json') > -1) {
                $('#delete-unit-weight-id').val(id);
                console.log(json.steel_stock_flg);
                if(json.steel_stock_flg == true) {
                  $('#delete-warning').show();
                  $('#delete-caution').hide();
                } else {
                  $('#delete-caution').show();
                  $('#delete-warning').hide();
                }
                $('#delete-modal').modal();
              }
            },
            error: function(xhr, status, e) {
              alert(status);
            }
          });
          return false;
        });
  
      defineItemNameCodeSelect();
      set_icheck();
    }
  });