// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  const maximum_item_length = 5; // 検索フォーム表示数

  if(document.location.pathname.match(/^\/stocks/)){
    function defineSearchForm() {
      // モーダルオープン
      $('.open-modal').off('click');
      $('.open-modal').on('click', function(){
        $('.steel_pipe_form_id').val($(this).parent().data('formId'));
      });

      // 検索
      $('.search-form').off('ajax:success');
      $('.search-form').on('ajax:success', function(event) {
        const [json, status, xhr] = event.detail;
        const contentType = xhr.getResponseHeader("content-type") || "";
        if (contentType.indexOf('html') > -1) {
          alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
          location.href = '/login';
        }
        if (contentType.indexOf('json') > -1) {
          if (json.error) {
            alert(json.error);
            return false;
          }
          $(this).find('.search-result').html(json.result);
          if ($('.item').length >= maximum_item_length && !json.research ) {
            deleteLastItem()
          }

          $('#items').prepend(json.item);

          invalidEnterKey();
          defineSearchForm();
          defineSizefullSelect();
          applyNumber();
          initForm();
        }
      });

      $('.search-form').off('ajax:error');
      $('.search-form').on('ajax:error', function(event) {
        const [json, status, xhr] = event.detail;
        alert(status);
      });

      // 検索結果削除
      $('.delete-row').off('click');
      $('.delete-row').on('click', function() {
        $(this).parents('.item').remove();
        applyNumber();
      });

      // 検索条件クリア
      $('.clear').off('click');
      $('.clear').on('click', function(){
        $(this).parents('.form').find('input').not('input\[name=stock_search\\[first_row\\]]').val('');
        $(this).parents('.form').find('.item-name, .size-full-select, .length-select, .steel-pipe-name-select, .search-result').html('');
        removeErrorMessage($(this).parents('.form').find('.length-text'))
      });
      // プルダウンメニューの検索
      $('select.select2').select2({
        placeholder: '品名・規格名を選択してください'
      });
    }

    // 各フォームに番号を振る
    function applyNumber() {
      $('.number').each(function(i){
        $(this).text(i+1);
      });
    }

    // 鋼管(カテゴリ)選択
    $('.steel-pipe-form').on('ajax:success', function(event) {
      const [json, status, xhr] = event.detail;
      const contentType = xhr.getResponseHeader("content-type") || "";
      if (contentType.indexOf('html') > -1) {
        alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
        location.href = '/login';
      }
      if (contentType.indexOf('json') > -1) {
        $(json.target_id + ' ' + '.item-name').html(json.item_name);
        $(json.target_id + ' ' + '.item-name-hidden').val(json.item_name);
        $(json.target_id + ' ' + '.size-full-select').html(json.size_fulls);
        $(json.target_id + ' ' + '.length-select').html([]);
        $(json.target_id + ' ' + '.item-name-select').html([]);
        $(json.target_id + ' ' + 'select.select2.size-full-select')
          .select2({placeholder: '選択してください'})
        $(json.target_id + ' ' + 'select.select2.size-full-select')
          .val(null).trigger('change.select2');
        if (json.steel_pipe_is_cuttable){
          $(json.target_id + ' ' + '.length-select-form').hide()
          $(json.target_id + ' ' + '.length-select-form select').prop('disabled', true)
          $(json.target_id + ' ' + '.length-text-form').show()
          $(json.target_id + ' ' + '.length-text-form input').prop('disabled', false)
          $(json.target_id + ' ' + '.length-text').val(null)
          $(json.target_id + ' ' + '.enter_length_text').html('長さ入力可')
        }else{
          $(json.target_id + ' ' + '.length-select-form').show()
          $(json.target_id + ' ' + '.length-select-form select').prop('disabled', false)
          $(json.target_id + ' ' + '.length-text-form').hide()
          $(json.target_id + ' ' + '.length-text-form input').prop('disabled', true)
          $(json.target_id + ' ' + '.enter_length_text').html(null)
          $(json.target_id + ' ' + 'select.select2.length-select')
            .select2({placeholder: '寸法を選択してください'})
        }
        $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
          .select2({placeholder: '寸法を選択してください'})
        $('#selected-steel-pipe-type').val(json.steel_pipe_type)
        defineSizefullSelect();
        removeErrorMessage($(json.target_id + ' ' + '.length-text'))

        if (json.is_size_full_search) {
          // 鋼種名セレクトボックスの横幅を合わせる
          let other_select_width = $(json.target_id + ' ' + 'select.select2.size-full-select').parent().find('.selection').width()

          $(json.target_id).find('#steel-pipe-name-select').show()
          $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select').parent().find('.select2-container').width(other_select_width)
          defineSteelPipeNameSelect(json.target_id)
        }else{
          $(json.target_id).find('#steel-pipe-name-select').hide()
        }
        $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
          .val(null).select2({placeholder: '寸法を選択してください'})
      }
      $(this).parents('.modal').modal('hide')
    });
    $('.steel-pipe-form').on('ajax:error', function(event) {
      const [json, status, xhr] = event.detail;
      alert(status);
    });

    function defineSizefullSelect() {
      $('.size-full-select').off('change.with-request');
      $('.size-full-select').on('change.with-request', function(event){
        let item_id = $(this).parents('.item').attr('id') ?? $(this).parents('.form').attr('id');

        $.ajax({
          url: '/stocks/steel_pipe_length',
          type: 'post',
          data: {
            item_id: item_id,
            item_name_text: $('#' + item_id + ' .item-name-hidden').val(),
            size_full_text: $(this).val(),
            steel_pipe_type: $('#selected-steel-pipe-type').val(),
          },
          success: function(json, status, xhr) {
            var contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              if ($(json.target_id + ' ' + '.item-name-hidden').val() == '寸法から選択'){
                if (json.is_cuttable){
                  $(json.target_id + ' ' + '.length-select-form').hide()
                  $(json.target_id + ' ' + '.length-select-form select').prop('disabled', true)
                  $(json.target_id + ' ' + '.length-text-form').show()
                  $(json.target_id + ' ' + '.length-text-form input').prop('disabled', false)
                  $(json.target_id + ' ' + '.length-text').val(null)
                  $(json.target_id + ' ' + '.enter_length_text').html('長さ入力可')
                }else{
                  $(json.target_id + ' ' + '.length-select-form').show()
                  $(json.target_id + ' ' + '.length-select-form select').prop('disabled', false)
                  $(json.target_id + ' ' + '.length-text-form').hide()
                  $(json.target_id + ' ' + '.length-text-form input').prop('disabled', true)
                  $(json.target_id + ' ' + '.enter_length_text').html(null)
                  if ($(json.target_id + ' ' + '.length-select').parent().find('.selection').length === 0) {
                    $(json.target_id + ' ' + 'select.select2.length-select')
                    .select2({placeholder: '寸法を選択してください'})
                  }
                }
              }
              if (json.is_cuttable){
                var length_text_select = $(json.target_id + ' ' + '.length-text-select')
                length_text_select.empty()
                length_text_select.append(json.lengths)
                console.log(json.lengths)
                if (json.single_flg) {
                  $(json.target_id + ' ' + '.length-text').val(json.length)
                  $(json.target_id + ' ' + '.length-text').trigger('change.with-request')
                }else {
                  $(json.target_id + ' ' + '.length-text').val(null)
                }
              }else{
                $(json.target_id + ' ' + '.length-select')
                  .html(json.lengths);
                if (json.single_flg) {
                  $(json.target_id + ' ' + 'select.select2.length-select')
                    .select2({placeholder: '選択してください'})
                  $(json.target_id + ' ' + 'select.select2.length-select')
                    .val(json.length).trigger('change.select2');
                  $(json.target_id + ' ' + 'select.select2.item-name-select')
                    .select2({placeholder: '長さを選択してください'})
                  $('.length-select').trigger('change.with-request')
                }else {
                  $(json.target_id + ' ' + 'select.select2.length-select')
                    .select2({placeholder: '選択してください'})
                  $(json.target_id + ' ' + 'select.select2.length-select')
                    .val(null).trigger('change.select2');
                  $(json.target_id + ' ' + 'select.select2.item-name-select')
                    .select2({placeholder: '長さを選択してください'})
                }
              }
              if($('#selected_text_length_val').length) {
                $(json.target_id + ' ' + '.length-text').val($('#selected_text_length_val').val())
                $('#selected_text_length_val').remove();
              }
              if($('#selected_length_val').length) {
                $(json.target_id + ' ' + 'select.select2.length-select').val($('#selected_length_val').val()).trigger('change.select2');
                $('#selected_length_val').remove();
              }
              $(json.target_id + ' ' + '.steel-pipe-name-select').html(null)
              $(json.target_id + ' ' + '.steel-pipe-name-select').select2({placeholder: '選択してください'})
              $(json.target_id + ' ' + '.selected-steel-pipe-max-length').val(json.steel_pipe_max_length)
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
      });
    }

    function defineSteelPipeNameSelect(target_id) {
      $('.length-select, .length-text').off('change.with-request');
      $('.length-select, .length-text').on('change.with-request', function(event){
        let item_id = $(this).parents('.item').attr('id') ?? $(this).parents('.form').attr('id');
        let is_none = $('#' + item_id).find('#steel-pipe-name-select').css('display') === "none";

        // 寸法から検索ではない場合はAPIリクエストを投げない
        if (is_none) { return; }

        $.ajax({
          url: '/stocks/steel_pipe_name',
          type: 'post',
          data: {
            item_id: item_id,
            size_full_text: $(target_id + ' ' + '.size-full-select').val(),
            length_text: $(this).val(),
            steel_pipe_type: $('#selected-steel-pipe-type').val()
          },
          success: function(json, status, xhr) {
            var contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              $(json.target_id + ' ' + '.steel-pipe-name-select')
                .html(json.steel_pipe_names);
              if (json.single_flg) {
                $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
                  .select2({placeholder: '選択してください'})
                $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
                  .val(json.steel_pipe_name).trigger('change.select2');
              }else {
                $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
                  .select2({placeholder: '選択してください'})
                $(json.target_id + ' ' + 'select.select2.steel-pipe-name-select')
                  .val(null).trigger('change.select2');
              }
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
      });
    }
    // ページ内inputでEnterキーを無効化
    function invalidEnterKey() {
      $("input").keydown(function(ev) {
        if((ev.key && ev.key === 'Enter') || (ev.which && ev.which === 13) || (ev.code && ev.code === 'Enter')) {
          return false;
        }
      });
    }

    function deleteLastItem() {
      let items = $('#items').find('.item')
      items[items.length - 1].remove()
    }

    function initForm() {
      $('.length-text').on('keyup change', function(e){
        validateNumericLengthForm($(e.target), 6)
      })
    }

    function validateNumericLengthForm(target, maxLength){
      var search_form = target.closest('.search-form')
      if (target.val() == '') {
        removeErrorMessage(target)
      }else if (target.val() > Number(search_form.find('.selected-steel-pipe-max-length').val())){
        addErrorMessage(target, 'ご指定いただけません。')
        search_form.find('.search_button').prop('disabled', true)
      }else if (overCharaLength(target.val(), maxLength)) {
        addErrorMessage(target, 'ご指定いただけません。')
        search_form.find('.search_button').prop('disabled', true)
      }else if (!isNumeric(target.val()) && target.val().slice(-1) !== '.'){
        addErrorMessage(target, 'ご指定いただけません。')
        search_form.find('.search_button').prop('disabled', true)
      }else {
        removeErrorMessage(target)
      }
    }

    function isNumeric(char){
      const integerRegex = /^-?\d+$/;
      const decimalRegex = /^-?\d+(\.\d+)?$/;

      return integerRegex.test(char) || decimalRegex.test(char);
    }

    function overCharaLength(str, maxLength){
      if (str.length <= maxLength) {
        return false;
      }else{
        return true;
      }
    }

    function addErrorMessage(target, message){
      if (target.parent('.has-error').length == 0) {
        target.wrap('<span class="has-error"></span>');
      }
      var error_message_element = $('<p class="error_message">').text(message);

      var error_message_area = target.closest('.form-group').find('.error_message_area')
      if (error_message_area.contents().length == 0) {
        error_message_area.append(error_message_element);
      }else{
        error_message_area.contents().replaceWith(error_message_element);
      }
    }

    function removeErrorMessage(target){
      var spanElement = target.parent('.has-error');

      // span要素の子要素を取得
      var childElement = spanElement.contents();

      // 子要素をspan要素の親要素に移動
      spanElement.replaceWith(childElement);

      target.closest('.form-group').find('.error_message').remove();
      target.closest('.search-form').find('.search_button').prop('disabled', false)
    }

    invalidEnterKey();
    defineSearchForm();
    defineSizefullSelect();
    defineSteelPipeNameSelect()
    applyNumber();
    initForm();

    $('#steel-pipe-name-select').hide();
  }
});
