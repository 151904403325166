$(document).on('turbolinks:load', function(){
    if(document.location.pathname.match(/^\/maintenance_schedules/)){
      function defineItemNameCodeSelect() {
        $('.select2').select2({placeholder: '選択してください'});
      }
      
      $('#maintenance-schedule-pager').on('ajax:success', 'li a', function(event){
        $('#maintenance-schedule-pager').html(event.detail[0]['paginator']);
        $('#maintenance-schedule-list > tbody').html(event.detail[0]['maintenance_schedules']);
        set_icheck();
      });
      
      function get_maintenance_schedule_id(){
        let $form = $('#maintenance-schedule-list-form');
        let list_param = $form.serializeArray();
        let maintenance_schedule_id = null;
        for(let i = 0; i < list_param.length; i++) {
          if(list_param[i]['name'] == 'id') {
            maintenance_schedule_id = list_param[i]['value'];
          };
        };
        return maintenance_schedule_id;
      }
      
      function set_icheck(){
        $('.icheck').iCheck({
          checkboxClass: 'icheckbox_square-blue',
          radioClass: 'iradio_square-blue'
        });
      }
  
      $('#new-maintenance-schedule').on('click', function(){
        $.ajax({
          url: '/maintenance_schedules/form/new.json',
          success: function(json, status, xhr) {
            var contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              $('#maintenance-schedule-attr-form').replaceWith(json.html);
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
  
        return false;
      });
    
      $('#delete-maintenance-schedule').on('click', function(){
        let id = get_maintenance_schedule_id();
        if (id == null) {
          alert('データを選択してください');
          return false;
        }
        $('#delete-maintenance-schedule-id').val(id);
        $('#delete-modal').modal();
        return false;
      });

      $('.calender').daterangepicker({
        startDate: moment(),
        singleDatePicker: true,
        autoUpdateInput: false
      });
      $('.calender').on('apply.daterangepicker', function(ev, picker) {
          $(this).val(picker.startDate.format('YYYY/MM/DD'));
      });
      
      defineItemNameCodeSelect();
      set_icheck();
    }
  });