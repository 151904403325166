$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/warehouses/)){
    let $table = $('#warehouse-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }
    
    $('#warehouse-pager').on('ajax:success', 'li a', function(event){
      $('#warehouse-pager').html(event.detail[0]['paginator']);
      $('#warehouse-list > tbody').html(event.detail[0]['warehouses']);
      set_icheck();
    });
    
    function get_warehouse_id(){
      let $form = $('#warehouse-list-form');
      let list_param = $form.serializeArray();
      let warehouse_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          warehouse_id = list_param[i]['value'];
        };
      };
      return warehouse_id;
    }
    
    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }

    $('#new-warehouse').on('click', function(){
      $.ajax({
        url: '/warehouses/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#warehouse-attr-form').replaceWith(json.html);
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });

      return false;
    });
  
    $('#delete-warehouse').on('click', function(){
      let id = get_warehouse_id();
      if (id == null) {
        alert('倉庫を選択してください');
        return false;
      }
      $('#delete-warehouse-id').val(id);
      $('#delete-modal').modal();
      return false;
    });
    
    defineItemNameCodeSelect();
    set_icheck();
  }
});