$(document).on('turbolinks:load', function() {
  $('.sidebar > ul > li > a').each(function(i) {
    var href = $(this).attr('href');
    if($(this).attr('href') == location.pathname) {
      $(this).parent().addClass('active');
    }
  });

  $('.sidebar > ul > li > a').on('click', function() {
    $(this).addClass('disabled');
  });
});
