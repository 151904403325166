// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/search_delivery_note$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    // チェックボックス
    $('input.icheck').iCheck({
      checkboxClass: 'icheckbox_square-blue',
      radioClass: 'iradio_square-blue'
    });
    $('#check_all').on('ifChecked', function(){
      $('.icheck.select').iCheck('check');
    });
    $('#check_all').on('ifUnchecked', function(){
      $('.icheck.select').iCheck('uncheck');
    });
    $('#check_clear').on('click', function(){
      $('.icheck.select').iCheck('uncheck');
    });

    $('.ids_check').on('click', function(){
      var ids = $('#download_form input[name="id[]"').serializeArray();
      if (ids.length <= 0) {
        alert('ダウンロードまたは印刷したい納品書にチェックを入れてください。');
        return false;
      }
      return true;
    });
  }

  $('[id^="check1_checkbox_"]').each(function(index, element){
    $(element).on('ifClicked', function(e){

      let url = $(e.target).data('url')
      let id = $(e.target).data('item-id')
      let checked = $(e.target).iCheck('update').parent().hasClass('checked')
      $.ajax({
        type: "get",
        url: url + `?is_checked=${!checked}&id=${id}`,
        success: function (json, status, xhr) {
          $(e.target).iCheck(!checked ? 'check' : 'uncheck')
        },
        error: function(xhr, status, e) {
          console.log("check_error")
        }
      });
    })
  })

  $('[id^="check2_checkbox_"]').each(function(index, element){
    $(element).on('ifClicked', function(e){

      let url = $(e.target).data('url')
      let id = $(e.target).data('item-id')
      let checked = $(e.target).iCheck('update').parent().hasClass('checked')

      $.ajax({
        type: "get",
        url: url + `?is_checked=${!checked}&id=${id}`,
        success: function (json, status, xhr) {
          $(e.target).iCheck(!checked ? 'check' : 'uncheck')
        },
        error: function(xhr, status, e) {
          console.log("check_error")
        }
      });
    })
  })

  // プレビュー画面を子画面として開き、閉じられた際の処理を行えるようにする
  $('.preview_button').each(function(index, element){
    $(element).on('click', function(event){
      url = $(event.target).data('url')

      let newWindwo = window.open(url, '_blank')
      $(newWindwo).on('unload', function(){
        console.log($('#close_preview_button').val())
        let close_button = $('#close_preview_button').val().split(' ')[0];
        let item_id = $('#close_preview_button').val().split(' ')[1];
        switch(close_button) {
            case 'check1':
              $(`#check1_checkbox_${item_id}`).iCheck('check')
              break;
            case 'check2':
              $(`#check2_checkbox_${item_id}`).iCheck('check')
              break;
            default:
        };
      })
    })
  })
  if(document.location.pathname.match(/^\/search_delivery_note\/\d+\/preview$/)){
    //プレビュー画面を閉じる
    $('#close_window').on('click', function(){
      $(window.opener.document).find('#close_preview_button').val('close')
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });

    $('#check1').on('click', function(e){
      e.preventDefault()
      url = $('#check1').data('url')
      item_id = $('#check1').data('item-id')
      $.ajax({
        type: "get",
        url: url,
        success: function (json, status, xhr) {
          $(window.opener.document).find('#close_preview_button').val(`check1 ${item_id}`)
          window.open('about:blank','_top').close(); //IE対策
          return false;
        },
        error: function(xhr, status, e){
          console.log('error')
        }
      });
    });

    $('#check2').on('click', function(e){
      e.preventDefault()
      url = $('#check2').data('url')
      item_id = $('#check1').data('item-id')
      $.ajax({
        type: "get",
        url: url,
        success: function (json, status, xhr) {
          $(window.opener.document).find('#close_preview_button').val(`check2 ${item_id}`)
          window.open('about:blank','_top').close(); //IE対策
          return false;
        },
        error: function(xhr, status, e){
          console.log('error')
        }
      });
    });
  }
});
