// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/search\/display$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    // 検索条件クリア
    $('#reset_form').on('click', function(){
      $('#search_form .form-control').each(function(index, element){
        $(element).val('');
      });
      return false;
    });

    // 詳細検索開閉
    if ($('#show_detail').prop('checked') == false){
      $('#toggle_detail_search').html('<i class="fa fa-plus"></i>');
      $('#detail_search_form').hide();
    }
    $('#toggle_detail_search').on('click', function(){
      if($('#detail_search_form').css('display') == 'none') {
        $('#toggle_detail_search').html('<i class="fa fa-minus"></i>');
        $('#show_detail').prop('checked', true);
      } else {
        $('#toggle_detail_search').html('<i class="fa fa-plus"></i>');
        $('#show_detail').prop('checked', false);
      }
      $('#detail_search_form').slideToggle();
      return false;
    });
  }

  if(document.location.pathname.match(/^\/search\/display\/\d+\/preview$/)){
    //ミルシートプレビュー画面を閉じる
    $('#close_window').on('click', function(){
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });

    // 表示toggle後にウィンドウを閉じる
    if(document.location.search.match("close=1")) {
      window.opener.location.reload(); // 検索画面リロード
      window.open('about:blank','_top').close(); //IE対策
      return false;
    }
  }
});