$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/steel_pipes/)){
    let $table = $('#steel-pipe-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }

    $('#steel-pipe-pager').on('ajax:success', 'li a', function(event){
      $('#steel-pipe-pager').html(event.detail[0]['paginator']);
      $('#steel-pipe-list > tbody').html(event.detail[0]['steel_pipes']);
      set_icheck();
    });

    function get_steel_pipe_id(){
        let $form = $('#steel-pipe-list-form');
        let list_param = $form.serializeArray();
        let steel_pipe_id = null;
        for(let i = 0; i < list_param.length; i++) {
          if(list_param[i]['name'] == 'id') {
            steel_pipe_id = list_param[i]['value'];
          };
        };
        return steel_pipe_id;
      }

    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }
    
    $('#new-steel-pipe').on('click', function(){
      $.ajax({
        url: '/steel_pipes/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#steel-pipe-attr-form').replaceWith(json.html);
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
  
      return false;
    });

    $('#copy-steel-pipe').on('click', function(){
        let id = get_steel_pipe_id();
        if (id == null) {
          alert('鋼管を選択してください');
          return false;
        }
        $.ajax({
          url: '/steel_pipes/form/' + id + '/copy.json',
          success: function(json, status, xhr) {
            var contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              $('#steel-pipe-attr-form').replaceWith(json.html);
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
    
        return false;
      });


    $('#delete-steel-pipe').on('click', function(){
        let id = get_steel_pipe_id();
        if (id == null) {
          alert('鋼管を選択してください');
          return false;
        }
        $.ajax({
          url: '/steel_pipes/' + id + '/stock_weight.json',
          success: function(json, status, xhr) {
            let contentType = xhr.getResponseHeader("content-type") || "";
            if (contentType.indexOf('html') > -1) {
              alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
              location.href = '/login';
            }
            if (contentType.indexOf('json') > -1) {
              $('#delete-steel-pipe-id').val(id);
              console.log(json.stock_weight_flg);
              if(json.stock_weight_flg == true) {
                $('#delete-warning').show();
                $('#delete-caution').hide();
              } else {
                $('#delete-caution').show();
                $('#delete-warning').hide();
              }
              $('#delete-modal').modal();
            }
          },
          error: function(xhr, status, e) {
            alert(status);
          }
        });
        return false;
      });

    defineItemNameCodeSelect();
    set_icheck();
  }
});