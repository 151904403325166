// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/user_data/)){
    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }
    set_icheck();

    function validateBoolean(input) {
      if (!($(input).val() == '1' || $(input).val() == '0' || $(input).val() == '')) {
        alert('0か1を入力してください');
        $(input).parent().removeClass('has-success');
        $(input).parent().addClass('has-error');
        return false;
      }
      $(input).parent().removeClass('has-error');
      return true;
    }

    function validateInteger(input) {
      if (isNaN($(input).val()) || $(input).val() < 0 || $(input).val() > 99999) {
        alert('5桁以内の数値を入力してください');
        $(input).parent().removeClass('has-success');
        $(input).parent().addClass('has-error');
        return false;
      }
      $(input).parent().removeClass('has-error');
      return true;
    }

    function validateString(input) {
      if ($(input).val().length > 50) {
        alert('50文字以内で入力してください');
        $(input).parent().removeClass('has-success');
        $(input).parent().addClass('has-error');
        return false;
      }
      $(input).parent().removeClass('has-error');
      return true;
    }

    function addEventListnerToInputs(target_class) {
      $('.' + target_class + ' .data-boolean').on('change', function(){
        $(this).parent().addClass('has-success');
        validateBoolean(this);
      });

      $('.' + target_class + ' .data-integer').on('change', function(){
        $(this).parent().addClass('has-success');
        validateInteger(this);
      });

      $('.' + target_class + ' .data-string').on('change', function(){
        $(this).parent().addClass('has-success');
        validateString(this);
      });
    }

    function expandUserDataInputs(target_class) {
      $("." + target_class).each(function(){
        var name = 'users[' + $(this).data('user-id') + '][user_data][' + $(this).data('item-id') + ']';
        var input_html;
        switch($(this).data('data-type')){
          case 'boolean':
            input_html = '<input type="number" class="form-control data-boolean" min="0" max="1" name="' + name + '" style="width:100%" value="' + $(this).data('value') + '">';
            break;
          case 'integer':
            input_html =  '<input type="number" class="form-control data-integer" min="0" max="99999" name="' + name + '" style="width:100%" value="' + $(this).data('value') + '">';
            break;
          case 'string':
            input_html =  '<input type="text" class="form-control data-string" maxlength="50" name="' + name + '" style="width:100%" value="' + $(this).data('value') + '">';
            break;
         }
         $(this).html(input_html);
      });
      addEventListnerToInputs(target_class);
    }

    $('#edit-user-data').on('click', function(){
      var target_class = $('.target-user:checked').data('target-class');
      if (target_class === undefined) {
        alert('編集したいレコードを選択してください');
        return;
      }
      expandUserDataInputs(target_class);
    });

    $('.user-data-editable').each(function(){
      var target_class = $(this).data('target-class');

      expandUserDataInputs(target_class);
    });

    $('#update-form').on('submit', function(e) {
      e.preventDefault();

      var has_error = false;

      $('.data-boolean').each(function(){
        if(!validateBoolean(this)){
          has_error = true;
          return false;
        }
      });
      if(has_error) return false;

      $('.data-integer').each(function(){
        if(!validateInteger(this)){
          has_error = true;
          return false;
        }
      });
      if(has_error) return false;

      $('.data-string').each(function(){
        if(!validateString(this)){
          has_error = true;
          return false;
        }
      });
      if(has_error) return false;

      this.submit();
    });
  }
});