$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/milsheet_company_masters/)){
    let $table = $('#milsheet_company_master-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });
    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }
    
    $('#milsheet_company_master-pager').on('ajax:success', 'li a', function(event){
      $('#milsheet_company_master-pager').html(event.detail[0]['paginator']);
      $('#milsheet_company_master-list > tbody').html(event.detail[0]['milsheet_company_masters']);
      set_icheck();
    });
    
    function get_milsheet_company_master_id(){
      let $form = $('#milsheet_company_master-list-form');
      let list_param = $form.serializeArray();
      let milsheet_company_master_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          milsheet_company_master_id = list_param[i]['value'];
        };
      };
      return milsheet_company_master_id;
    }
    
    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }

    function display_company_name() {
      var code = $('#company_code_field').val()
      get_and_show_company_name('#company_name', code)
    }

    function get_and_show_company_name(target, code){
      if(code && code.length == 8) {
        $.get('/companies/code/' + code + '.json', function(json) {
          if(json && json.name) {
            $(target).text('会社名　' + json.name);
          }
        });
      } else {
        $(target).text('会社マスタに該当する会社がありません');
      }
    }

    $('#delete-milsheet_company_master').on('click', function(){
      let id = get_milsheet_company_master_id();
      if (id == null) {
        alert('削除する得意先を選択してください');
        return false;
      }
      $('#delete-milsheet_company_master-id').val(id);
      $('#delete-modal').modal();
      return false;
    });

    $('#company_code_field').on('keyup', display_company_name);
    display_company_name();
    defineItemNameCodeSelect();
    set_icheck();
  }
});
