$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/stock_thresholds/)){
    let $table = $('#stock-threshold-list');
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }
  
    $('#stock-threshold-pager').on('ajax:success', 'li a', function(event){
      $('#stock-threshold-pager').html(event.detail[0]['paginator']);
      $('#stock-threshold-list > tbody').html(event.detail[0]['stock_thresholds']);
      set_icheck();
    });
  
    function get_stock_threshold_id(){
      let $form = $('#stock-threshold-list-form');
      let list_param = $form.serializeArray();
      let stock_threshold_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          stock_threshold_id = list_param[i]['value'];
        };
      };
      return stock_threshold_id;
    }
  
    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }

    $('#change-stock-threshold').on('click', function(){
      let id = get_stock_threshold_id();
      if (id == null) {
        alert('データを選択してください');
        return false;
      }
      $.ajax({
        url: '/stock_thresholds/form/' + id + '.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#stock-threshold-attr-form').replaceWith(json.html);
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
  
      return false;
    });


    $('#new-stock-threshold').on('click', function(){
      $.ajax({
        url: '/stock_thresholds/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#stock-threshold-attr-form').replaceWith(json.html);
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
    
      return false;
    });

    $('#delete-stock-threshold').on('click', function(){
      let id = get_stock_threshold_id();
      if (id == null) {
        alert('データを選択してください');
        return false;
      }
      $.ajax({
        url: '/stock_thresholds/' + id + '/steel_weight.json',
        success: function(json, status, xhr) {
          let contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#delete-stock-threshold-id').val(id);
            console.log(json.steel_weight_flg);
            if(json.steel_weight_flg == true) {
              $('#delete-warning').show();
              $('#delete-caution').hide();
            } else {
              $('#delete-caution').show();
              $('#delete-warning').hide();
            }
            $('#delete-modal').modal();
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
      return false;
    });
  
    defineItemNameCodeSelect();
    set_icheck();
  }
});