// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
import 'admin-lte/plugins/iCheck/icheck';
import 'moment/locale/ja';
import 'bootstrap-daterangepicker';

$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/search_invoice\/display\/?$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    // チェックボックス
    $('input.icheck').iCheck({
      checkboxClass: 'icheckbox_square-blue',
      radioClass: 'iradio_square-blue'
    });
    $('#check_all').on('ifChecked', function(){
      $('.icheck').iCheck('check');
    });
    $('#check_all').on('ifUnchecked', function(){
      $('.icheck').iCheck('uncheck');
    });
    $('#check_clear').on('click', function(){
      $('.icheck').iCheck('uncheck');
    });

    $('.ids_check').on('click', function(){
      var ids = $('#toggle_form input[name="id[]"').serializeArray();
      if (ids.length <= 0) {
        alert('表示制御したい請求書にチェックを入れてください。');
        return false;
      }
      return true;
    });
  }

  if(document.location.pathname.match(/^\/search_invoice\/display\/\d+\/preview$/)){
    $('#close_window').on('click', function(){
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });

    // 表示toggle後にウィンドウを閉じる
    if(document.location.search.match("close=1")) {
      window.opener.location.reload(); // 検索画面リロード
      window.open('about:blank','_top').close(); //IE対策
      return false;
    }
  }
});