// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/search$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    // 検索条件クリア
    $('#reset_form').on('click', function(){
      $('#search_form .form-control').each(function(index, element){
        $(element).val('');
      });
      return false;
    });

    // チェックボックス
    $('input.icheck').iCheck({
      checkboxClass: 'icheckbox_square-blue',
      radioClass: 'iradio_square-blue'
    });
    $('#check_all').on('ifChecked', function(){
      $('.icheck').iCheck('check');
    });
    $('#check_all').on('ifUnchecked', function(){
      $('.icheck').iCheck('uncheck');
    });
    $('#check_clear').on('click', function(){
      $('.icheck').iCheck('uncheck');
    });

    // 詳細検索開閉
    if ($('#show_detail').prop('checked') == false){
      $('#toggle_detail_search').html('<i class="fa fa-plus"></i>');
      $('#detail_search_form').hide();
    }
    $('#toggle_detail_search').on('click', function(){
      if($('#detail_search_form').css('display') == 'none') {
        $('#toggle_detail_search').html('<i class="fa fa-minus"></i>');
        $('#show_detail').prop('checked', true);
      } else {
        $('#toggle_detail_search').html('<i class="fa fa-plus"></i>');
        $('#show_detail').prop('checked', false);
      }
      $('#detail_search_form').slideToggle();
      return false;
    });

    $('.multi_print').on('click', function(){
      var printable = true;
      $(".checked_sheet:checked").each(function() {
        if ( $(this).data('protected') == '1') {
          alert('選択したミルシートにはブラウザからの印刷に対応していないものが含まれています。恐れ入りますがダウンロードをしてから印刷をお願いします。');
          printable = false;
          return false;
        }
      });
      return printable;
    });

    $('.ids_check').on('click', function(){
      var ids = $('#download_form input[name="id[]"').serializeArray();
      if (ids.length <= 0) {
        alert('ダウンロードまたは印刷したいミルシートにチェックを入れてください。');
        return false;
      }
      return true;
    });
  }

  if(document.location.pathname.match(/^\/search\/\d+\/(preview|preview_delivery_document)$/)){
    //ミルシートプレビュー画面を閉じる
    $('#close_window').on('click', function(){
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });

    //送付案内書がヒットしない時のメッセージ表示
    $('#delivery_document_check').on('click', function(){
      alert("ミルシート送付案内書が見つかりませんでした");
    });
  }

  $('.print-disabled').on('click', function(){
    alert("選択したミルシートはブラウザからの印刷に対応しておりません。\n恐れ入りますがダウンロードをしてから印刷をお願いします。");
  });
});
