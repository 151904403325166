// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/user_data_items/)){
    $('.edit-name').on('click', function(){
      user_date_item_id = $(this).data('user-data-item-id');
      $('#edit-button-' + user_date_item_id).attr('disabled', true);
      $('#delete-button-' + user_date_item_id).attr('disabled', true);
      $('#delete-button-' + user_date_item_id).addClass('disabled');
      $('#name-label-' + user_date_item_id).addClass('hidden');
      $('#name-form-' + user_date_item_id).removeClass('hidden');
    });
  }
});