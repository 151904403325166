// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/search_milsheet$/)){
    // カレンダー
    $('.calender').daterangepicker({
      startDate: moment(),
      singleDatePicker: true,
      autoUpdateInput: false
    });
    $('.calender').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('YYYY/MM/DD'));
    });

    // 検索条件クリア
    $('#reset_form').on('click', function(){
      $('#search_form .form-control').each(function(index, element){
        $(element).val('');
      });

      // 出荷開始日のみ一週間前の日付を入力する
      var date = new Date()
      date.setDate(date.getDate() - 7)
      month = date.getMonth() + 1
      day = date.getDate()
      //月と日が一桁の場合は先頭に0をつける
      month_text = month < 10 ? `0${month}` : month.toString()
      day_text = day < 10 ? `0${day}` : day.toString()
      var formated_date = `${date.getFullYear()}/${month_text}/${day_text}`
      $('#ship_dt_from').val(formated_date)

      return false;
    });

    // チェックボックス
    $("input.icheck.margin.checked_sheet").each(function(index,checkbox){
      $(checkbox).change(function(data){
        set_sent_flag(data.currentTarget.id, data.currentTarget.checked)
      })
    })

    $('#send_status_radio').css('cssText', 'display: block !important');
    $('#use_print').change(function(data){
      if (data.currentTarget.checked) {
        $('#send_status_radio').show();
      }
    });
    $('#include_milsheet_company_master').change(function(data){
      if (data.currentTarget.checked) {
        $('#send_status_radio').hide();
      }
    });

    function set_sent_flag(id_str, sent_flag_value){
      let id = id_str.replace("id_","")

      $.ajax({
        url: `/search_milsheet/change_sent_flag?id=${id}&send_flag=${sent_flag_value}`,
        success: function(json, status, xhr) {
        },
        error: function(xhr, status, e){
          $(`#${id_str}`)[0].checked = false
          alert(status);
        }
      });

      return false;
    }

    if($('#use_print')[0].checked) {
      $('#send_status_radio').show();
    }
    else {
      $('#send_status_radio').hide();
    }
  }

  if(document.location.pathname.match(/^\/search_milsheet\/\d+\/(preview|preview_delivery_document)$/)){
    //ミルシートプレビュー画面を閉じる
    $('#close_window').on('click', function(){
      window.open('about:blank','_top').close(); //IE対策
      return false;
    });

    //送付案内書がヒットしない時のメッセージ表示
    $('#delivery_document_check').on('click', function(){
      alert("ミルシート送付案内書が見つかりませんでした");
    });
  }

  $('.print-disabled').on('click', function(){
    alert("選択したミルシートはブラウザからの印刷に対応しておりません。\n恐れ入りますがダウンロードをしてから印刷をお願いします。");
  });
});
