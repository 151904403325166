// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function() {
  if(document.location.pathname.match(/^\/users\/(new|\d+)$/)){
    function disable_companies() {
      var disable = $('input[name="user[role]"]:checked').val() != "4" // 一般ユーザ（複数拠点閲覧可）でない
      if(disable) {
        $('.company-list').hide();
      } else {
        $('.company-list').show();
      }
    }

    function display_company_name() {
      var code = $('input[name="user[company_code]"]').val()
      get_and_show_company_name('#company_name', code)
    }

    function display_ec_company_name() {
      var code = $('input[name="user[ec_company_code]"]').val()
      get_and_show_company_name('#ec_company_name', code)
    }

    function display_new_visible_company_name() {
      var code = $('input[name="company_code"]').val()
      get_and_show_company_name('#new_visible_company_name', code)
    }

    function get_and_show_company_name(target, code){
      if(code && code.length == 8) {
        $.get('/companies/code/' + code + '.json', function(json) {
          if(json && json.name) {
            $(target).text(json.name);
          }
        });
      } else {
        $(target).text('該当する会社がありません');
      }
    }

    function add_visible_company(e) {
      e.preventDefault();

      var form_data = new FormData();
      form_data.append('code', $('input[name="company_code"]').val());
      $('input[name="user[visible_company_ids][]"]').each(
        function(index, element) {
          form_data.append('company_codes[]',element.value);
        }
      );

      Rails.ajax({
        type: 'post',
        url: '/users/add_visible_company',
        data: form_data,
        dataType: "script"
      });
    }

    function delete_visible_company(e) {
      if (confirm("選択した行について、「閲覧させたい会社」から削除しますか?")) {
        e.target.parentElement.remove();
      }
    }

    $('input.icheck').iCheck({
      radioClass: 'iradio_square-blue'
    });

    $('input[name="user[role]"]').on('ifChecked', disable_companies);
    $('input[name="user[company_code]"]').on('keyup', display_company_name);
    $('input[name="user[ec_company_code]"]').on('keyup', display_ec_company_name);
    $('input[name="company_code"]').on('keyup', display_new_visible_company_name);
    $('#add_visible_company').on('click', add_visible_company);
    $('.delete-visible-company').on('click', delete_visible_company);
    disable_companies();
    display_company_name();
    display_ec_company_name();
    window.delete_visible_company = delete_visible_company;
  }
});
