$(document).on('turbolinks:load', function(){
  if(document.location.pathname.match(/^\/steel_pipe_item_names/)){
    $table = $('#steel-pipe-item-name-list')
    $table.floatThead({
      scrollContainer: function($table){
        return $table.closest('.table-responsive');
      }
    });

    function defineItemNameCodeSelect() {
      $('.select2').select2({placeholder: '選択してください'});
    }

    $('#steel-pipe-item-name-pager').on('ajax:success', 'li a', function(event){
      $('#steel-pipe-item-name-pager').html(event.detail[0]['paginator']);
      $('#steel-pipe-item-name-list > tbody').html(event.detail[0]['steel_pipe_item_names']);
      set_icheck();
    });

    function get_steel_pipe_item_name_id(){
      let $form = $('#steel-pipe-item-name-list-form');
      let list_param = $form.serializeArray();
      let steel_pipe_item_name_id = null;
      for(let i = 0; i < list_param.length; i++) {
        if(list_param[i]['name'] == 'id') {
          steel_pipe_item_name_id = list_param[i]['value'];
        };
      };
      return steel_pipe_item_name_id;
    }

    function set_icheck(){
      $('.icheck').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
      });
    }

    $('#change-steel-pipe-item-name').on('click', function(){
      let id = get_steel_pipe_item_name_id();
      if (id == null) {
        alert('品名規格を選択してください');
        return false;
      }
      $.ajax({
        url: '/steel_pipe_item_names/form/' + id + '.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#steel-pipe-item-name-attr-form').replaceWith(json.html);
          }
          set_icheck();
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });

      return false;
    });


    $('#new-steel-pipe-item-name').on('click', function(){
      $.ajax({
        url: '/steel_pipe_item_names/form/new.json',
        success: function(json, status, xhr) {
          var contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#steel-pipe-item-name-attr-form').replaceWith(json.html);
          }
          set_icheck();
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
    
      return false;
    });

    $('#delete-steel-pipe-item-name').on('click', function(){
      let id = get_steel_pipe_item_name_id();
      if (id == null) {
        alert('品名規格を選択してください');
        return false;
      }
      $.ajax({
        url: '/steel_pipe_item_names/' + id + '/linking_steel_pipe.json',
        success: function(json, status, xhr) {
          let contentType = xhr.getResponseHeader("content-type") || "";
          if (contentType.indexOf('html') > -1) {
            alert('無操作状態から一定時間が経過したため、自動的にログアウトされました。\n再度ログインしてください。');
            location.href = '/login';
          }
          if (contentType.indexOf('json') > -1) {
            $('#delete-steel-pipe-item-name-id').val(id);
            console.log(json.is_linking);
            if(json.is_linking > 0) {
              $('#delete-warning').show();
              $('#delete-confirm').hide();
            } else {
              $('#delete-warning').hide();
              $('#delete-confirm').show();
            }
            $('#delete-modal').modal();
          }
        },
        error: function(xhr, status, e) {
          alert(status);
        }
      });
      return false;
    });
  
    defineItemNameCodeSelect();
    set_icheck();
  }
});